import React from 'react';
import './../assets/styles/Scholarship.css';
import ListCard from './ListCard';

import EVEE from './../assets/images/projects/EVEE.jpeg';
import RADIOYLLA from './../assets/images/projects/RADIO-YLLA.png';
import ONGYLLA from './../assets/images/projects/ONG-YLLA.png';
import LOVINGSCOOKS from './../assets/images/projects/LOVINGS-COOK.png';
import EDIGIOWEB from './../assets/images/projects/EDIGIO-WEB.png';
import DIABYCONSEIL from './../assets/images/projects/DIABY-CONSEIL.png';
import NAN from './../assets/images/projects/NAN.png';
import WILLOANGELS from './../assets/images/projects/WILLOANGELS.png';

const XP = (props) => {
  let i = 0;
  const next = () => {
    return ++i;
  }
  const xp = {
    'English': [
      {
        id: next(),
        src: EVEE,
        title : 'Chief Technology Officer (CTO) - EVEE INC',
        certificate: `Chief Technology Officer (CTO)`,
        year : '2024',
        duration : `On progress`,
        website: 'https://eveecorp.link',
        summary : [
          `Chief Technology Officer at Evee Engineering`,
          `Technologies : Flutter, Dart (Knowledges of Android and iOS applications architecture )`,
          `React Native`,
        ]
      },
      {
        id: next(),
        src: RADIOYLLA,
        title : 'Mobile Developer - Radio Ylla',
        certificate: `Mobile Developer`,
        year : '2024',
        duration : `Done`,
        website: '#',
        summary : [
          `Flutter app developer`,
          `Web Radio app for Android and iOS`,
          `Technologies : Flutter, Dart (Knowledges of Android and iOS applications architecture )`,
        ]
      },
      {
        id: next(),
        src: LOVINGSCOOKS,
        title : 'Mobile Developer - LOVINGS COOK',
        certificate: `Mobile Developer`,
        year : '2023',
        duration : `On progress`,
        website: '#',
        summary : [
          `Food delivery mobile app developer`,
          `API conception`,
          `Technologies : MERN STACK - MongoDB, ExpressJs, React/React Native, NodeJS, GIT (GITHUB)`,
        ]
      },
      {
        id: next(),
        src: ONGYLLA,
        title : 'LEAD Developer - ONG YLLA',
        certificate: `Volunteer Developer at ONG (Non-Gouvernment Organisation)`,
        year : '2023',
        duration : `Until Now`,
        website: 'https://ongylla.org/',
        summary : [
          `Lead Developer at ONG YLLA`,
          'Host : Hostinger',
          `Technologies : WordPress, Elementor, HTML, CSS, JAVASCRIPT`,
        ]
      },
      {
        id: next(),
        src: EDIGIOWEB,
        title : 'LEAD Developer - EDIGIOWEB',
        certificate: `Freelancer`,
        year : '2023',
        duration : `Until Now`,
        website: 'https://edigioweb.com/',
        summary : [
          `Lead Developer in Freelance at EDIGIOWEB.`,
        ]
      },
      {
        id: next(),
        src: DIABYCONSEIL,
        title : 'Real estate website - DIABYCONSEIL',
        certificate: `WEB Developer`,
        year : '2020',
        duration : `Until January 2024`,
        website: 'https://diabyconseil.fr/',
        summary : [
          `Secure administration Dashboard.`,
          `Independant client space`,
          'Host : PlanetHoster',
          `Technologies : HTML5, CSS3, JAVASCRIPT, PHP, JSON.`,
        ]
      },
      {
        id: next(),
        src: NAN,
        title : `NOT A NUMBER - IVORY COAST (REMOTE FROM FRANCE)`,
        certificate: `Student`,
        year : '2020',
        duration : '1 year',
        website: 'https://nan.ci/',
        summary : [
          `Programmer Student at Not A Number.`,
          `By EXAM. 3.20 Generation - Golang Speciality`,
          `PS: This year, i didn't wash my hands only : Covid-19. 😀`,
          `Technologies : Go, GIT (GITHUB)`,
        ]
      },
      {
        id: next(),
        src: WILLOANGELS,
        title : `WILLO ANGELS PARTNER &amp; DIAMOND ART - IVORY COAST`,
        certificate: `Developer`,
        year : '2019',
        duration : '1 an',
        website: 'https://ibrahimdiaby.github.io/willxangel/',
        summary : [
          `Remote collaboration.`,
          `Vitrine website WilloAngels conception.`,
          'Host : Github Pages',
          `Technologies : HTML5, CSS3, JAVASCRIPT, GIT (GITHUB)`,
        ]
      },
      {
        id: next(),
        src: NAN,
        title : `NOT A NUMBER - IVORY COAST`,
        certificate: `Student`,
        year : '2018',
        duration : '1 year',
        website: 'https://nan.ci/',
        summary : [
          `Programmer and Network Student at Not A Number.`,
          `By EXAM. 1.18 Generation`, 
          `Logic Test and Mini-game conception.`,
        ]
      },
      {
        id: next(),
        src: NAN,
        title : `EQUIPMENTS TESTERS AND STUDENT AT NOT A NUMBER - IVORY COAST`,
        certificate: `Student - Tester`,
        year : '2017',
        duration : '2 weeks - 1 month',
        website: 'https://nan.ci/',
        summary : [
          `Tester of NOT A NUMBER (NaN) equipments with French ex-students of 42.`,
          `Student of JavaScript ES6. (Pool)`,
          `Technologies : JavaScript(ES6), NodeJS, npm, GIT (GITHUB)`,
        ]
      }
  ],
    'French': [
      {
        id: next(),
        src: EVEE,
        title : 'Directeur de la technologie (CTO) - EVEE ENGINEERING',
        certificate: `Directeur de la technologie (CTO)`,
        year : '2024',
        duration : `Jusqu'à aujourd'hui`,
        website: 'https://eveecorp.link',
        summary : [
          `Directeur de la technologie à Evee Engineering`,
          `Technologies : Flutter, Dart (Connaissances des architectures des applications Android et iOS)`,
          `React Native`,
        ]
      },
      {
        id: next(),
        src: RADIOYLLA,
        title : 'Développeur Mobile - Radio Ylla',
        certificate: `Développeur Mobile`,
        year : '2024',
        duration : `Publié`,
        website: '#',
        summary : [
          `Développeur Mobile Flutter`,
          `Création d'une application mobile de Web Radio Native multi-plateforme (Android and iOS).`,
          `Technologies : Flutter, Dart (Connaissance en architecture des applications Android et iOS)`,
        ]
      },
      {
        id: next(),
        src: LOVINGSCOOKS,
        title : 'Développeur Mobile - LOVINGS COOK',
        certificate: `Développeur Mobile`,
        year : '2023',
        duration : `En cours`,
        website: '#',
        summary : [
          `Développeur d'une application de livraison de nourriture et gestion d'une entreprise de restauration.`,
          `Conception d'API`,
          `Technologies : MERN STACK - MongoDB, ExpressJs, React/React Native, NodeJS, GIT (GITHUB)`,
        ]
      },
      {
        id: next(),
        src: ONGYLLA,
        title : 'Lead DEV - ONG YLLA',
        certificate: `Développeur volontaire en ONG (Organisation Non-Gouvernementale)`,
        year : '2023',
        duration : `Jusqu'à aujourd'hui`,
        website: 'https://ongylla.org/',
        summary : [
          `Lead Développeur à ONG YLLA`,
          'Hébergeur : Hostinger',
          `Technologies : WordPress, Elementor, HTML, CSS, JAVASCRIPT`,
        ]
      },
      {
        id: next(),
        src: EDIGIOWEB,
        title : 'LEAD DEV - EDIGIOWEB',
        certificate: `Freelance`,
        year : '2023',
        duration : `Jusqu'à aujourd'hui`,
        website: 'https://edigioweb.com/',
        summary : [
          `Lead Développeur en Freelance à EDIGIOWEB.`,
        ]
      },
      {
        id: next(),
        src: DIABYCONSEIL,
        title : 'CREATION DU SITE IMMOBILIER - DIABYCONSEIL',
        certificate: `Développeur Web`,
        year : '2020',
        duration : `Jusqu'en Janvier 2024`,
        website: 'https://diabyconseil.fr/',
        summary : [
          `Espace d'administration dedié securisé.`,
          `Espace client indépendant de l'espace administration.`,
          `Hébergeur : PlanetHoster`,
          `Technologies : HTML5, CSS3, JAVASCRIPT, PHP, JSON.`,
        ]
      },
      {
        id: next(),
        src: NAN,
        title : `NOT A NUMBER - CÔTE D'IVOIRE (DEPUIS LA FRANCE)`,
        certificate: `Étudiant`,
        year : '2020',
        duration : '1 an',
        website: 'https://nan.ci/',
        summary : [
          `Étudiant ayant suivi la formation Not A Number dans le domaine de la programmation.`,
          `Adhésion sous concours. Generation 3.20 - Spécialité Golang`,
          `PS: Je crois qu'on peut dire que je n'ai pas fais que me laver les mains en vue du Covid-19. 😀`,
          `Technologies: Go, GIT (GITHUB)`,
        ]
      },
      {
        id: next(),
        src: WILLOANGELS,
        title : `PARTENAIRE WILLO ANGELS &amp; DIAMOND ART - CÔTE D'IVOIRE`,
        certificate: `Développeur`,
        year : '2019',
        duration : '1 an',
        website: 'https://ibrahimdiaby.github.io/willxangel/',
        summary : [
          `Collaboration à distance (Télé-travail).`,
          `Conception du site web vitrine WilloAngels.`,
          `Technologies : HTML5, CSS3, JAVASCRIPT, GIT (GITHUB).`,
        ]
      },
      {
        id: next(),
        src: NAN,
        title : `NOT A NUMBER - CÔTE D'IVOIRE`,
        certificate: `Étudiant`,
        year : '2018',
        duration : '1 an',
        website: 'https://nan.ci/',
        summary : [
          `Étudiant ayant suivi la formation Not A Number (NaN) dans le domaine de la programmation et du réseau.`,
          `Adhésion sous concours. Génération 1.18 :`, 
          `Test de logique et programmation d'un mini jeu.`,
        ]
      },
      {
        id: next(),
        src: NAN,
        title : `TESTEURS D'ÉQUIPEMENT DE NOT A NUMBER - CÔTE D'IVOIRE`,
        certificate: `Étudiant Testeur`,
        year : '2017',
        duration : '2 semaines - 1 mois',
        website: 'https://nan.ci/',
        summary : [
          `Membre des Étudiants ayant testé les installations de NOT A NUMBER (NaN) sous légit d'ex étudiants Français de 42.`,
          `Étudiant de la piscine axé sur du JavaScript ES6.`,
          `Technologies : JavaScript(ES6), NodeJS, npm, GIT (GITHUB)`,
        ]
      }
    ]
  }
  
  
  return (
    <>
      <section className='scholarship-container'>
        {
          xp[props.lang].map(item => {
            return (
              <div key={item.id} className='degree-container'>
                <ListCard XP_DEGREE={item} />
              </div>
              )  
            })
        }
      </section>
    </>
  );
}

export default XP;
